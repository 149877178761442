import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/app/components/RichText.module.scss");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/ecommerce/ProductsCarousel.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/general/Banner.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/general/Faq.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/general/FullWidthImage.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/general/Hero.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/general/HeroSlider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/general/NewsletterBanner.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/general/ThreeBlocks.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/general/VideoHero.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/Layout/Header/NotificationBanner.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/search/search.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1659611679/src/src/components/util/ServerClientCommunication.tsx")